
import { defineComponent, reactive, toRaw, ref, onMounted } from "vue";
import { Form, message } from "ant-design-vue";
import { loginFormRules } from "./formConfig/loginFormConfig";

import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import "@/assets/styles/login.scss";
import { useRouter, useRoute } from "vue-router";

import Cookies from "js-cookie";
import { AES_Encrypt, setToken } from "@/utils";
import { supplierlogin, userLogin } from "@/api/login";
import ImageCode from "@/components/ImageCode/index.vue";
import { supplierInfo } from "@/api/supplierManage";
interface FormState {
  username: string;
  password: string;
  code: string;
  // upn?: boolean;
  uuid: string;
  // upnArr: Array<any>;
}
const useForm = Form.useForm;
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    ImageCode,
  },
  setup() {
    const formState = reactive<FormState>({
      username: "",
      password: "",
      code: "",
      // upn: false,
      uuid: "",
      // upnArr: [],
    });
    const router = useRouter();
    const route = useRoute();
    const client = route.name === "userLogin" ? "user" : "supplier";
    sessionStorage.setItem("client", client);
    Cookies.set("client", client)
    const rulesForm = reactive(loginFormRules());
    const { resetFields, validate, validateInfos } = useForm(
      formState,
      rulesForm
    );
    const imgSrc = ref("");
    const loginLoading = ref(false);
    let time = ref(0);
    const toLogon = () => {
      router.push("/supplier/logon");
    };
    const onSubmit = () => {
      validate()
        .then(() => {
          console.log(toRaw(formState));

          const params = {
            ...toRaw(formState),
            password: AES_Encrypt(formState.password),
            // password: formState.password,
          };
          // delete params.upn;
          loginLoading.value = true;
          if (route.name === "userLogin") {
            userLogin(params)
              .then((res: any) => {
                if (res.code == 200) {
                  setToken(res.token);
                  // Cookies.set("username", params.username);
                  sessionStorage.setItem("username", params.username);
                  router.replace("/user/supplierManage");
                } else {
                  message.error(res.msg);
                  time.value += 1;
                }
                loginLoading.value = false;
              })
              .catch(() => {
                loginLoading.value = false;
                time.value += 1;
              });
          } else {
            supplierlogin(params)
              .then((res: any) => {
                if (res.code == 200) {
                  setToken(res.token);
                  // Cookies.set("username", params.username);
                  sessionStorage.setItem("username", params.username);
                  getSupplierInfo(params.username);
                } else {
                  message.error(res.msg);
                  time.value += 1;
                }
                loginLoading.value = false;
              })
              .catch(() => {
                loginLoading.value = false;
                time.value += 1;
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const resetPassword = () => {
      router.push("/resetPassword");
    };

    const getSupplierInfo = (userName) => {
      supplierInfo({ userName }).then((res: any) => {
        //console.log(res);
        if (res.code === 200) {
          // router.replace("/supplier/infomation");
          if (res.data.supplier.attachments.length) {
            sessionStorage.setItem("state", res.data.supplier.state);
            router.replace("/supplier/infomation");
          } else {
            router.replace({
              path: "/supplier/logon",
              query: {
                userId: res.data.supplier.id,
                name: res.data.supplier.companyFullName,
                userName,
              },
            });
          }
        }
      });
    };
    const updateUUID = (uuid) => {
      formState.uuid = uuid;
    };

    return {
      formState,
      validateInfos,
      resetFields,
      onSubmit,
      toLogon,
      route,
      resetPassword,
      imgSrc,
      loginLoading,
      updateUUID,
      time,
    };
  },
});

import { createStore } from "vuex";

export default createStore({
  state: {
    userInfo: {},
  },
  getters: {},
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
  },
  actions: {
    setUserInof: ({ commit }, userInfo) => {
      commit("SET_USERINFO", userInfo);
    },
  },
  modules: {},
});

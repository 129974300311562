import { phoneVal, passwordVal } from "@/utils/validator";
export const stepsArr = [
  {
    title: "验证身份",
  },
  {
    title: "设置新密码",
  },
  {
    title: "完成",
  },
];

export const userRules = () => {
  return {
    username: [
      {
        required: true,
        message: "请输入用户名",
      },
    ],
    phonenumber: [
      {
        required: true,
        validator: phoneVal,
      },
    ],
    code: [
      {
        required: true,
        message: "请输入验证码",
      },
    ],
    smsCode: [
      {
        required: true,
        message: "请输入手机验证码",
      },
    ],
  };
};

export const passwordRules = (repasswordVal: any) => {
  return {
    password: [
      {
        required: true,
        validator: passwordVal,
      },
    ],
    rePassword: [
      {
        required: true,
        validator: repasswordVal,
      },
    ],
  };
};

import { promisePost } from "./httpRequest";

const supplier = "/supplier";
//供应商列表
export const suppliertList = (params) => {
  return promisePost(`${supplier}/getAllSuppliersInfo`, params);
};

// 供应商信息
export const supplierInfo = (params) => {
  return promisePost(`${supplier}/getSupplierInfo`, params);
};
//供应商信息审核
export const approval = (params) => {
  return promisePost(`${supplier}/approval`, params);
};
//账号停用 启用
export const changeStatus = (params) => {
  return promisePost(`${supplier}/changeSupplierStatus`, params);
};

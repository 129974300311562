import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Layout from "../views/Layout/index.vue";
import { formConfig } from "@/views/ProjectManage/projectConfig";
import { removeToken } from "@/utils";
import Cookies from "js-cookie";
const client = sessionStorage.getItem("client");
const routes: any = [
  {
    path: "/",
    name: "",
    component: Login,
    redirect: "/supplier",
    meta: {
      noBreadCrumb: true,
    },
    beforeEnter: (to, form, next) => {
      next();
    },
  },
  {
    path: "/user/login",
    name: "userLogin",
    component: Login,
    meta: {
      noBreadCrumb: true,
    },
  },

  {
    path: "/supplier/login",
    name: "supplierLogin",
    component: Login,
    meta: {
      noBreadCrumb: true,
    },
  },
  {
    path: "/supplier",
    component: Layout,
    redirect: "/supplier/login",
    meta: {
      noBreadCrumb: true,
    },
    children: [
      {
        path: "supplierHome",
        name: "supplierHome",
        component: () => import("../views/SupplierHome/index.vue"),
        meta: { title: "供应商首页", noBreadCrumb: false },
      },
      {
        path: "biddingHome",
        name: "biddingHome",
        meta: { title: "供应商大厅", noBreadCrumb: false },
        component: () => import("../views/BiddingHome/index.vue"),
        children: [
          {
            path: "biddingDetail",
            name: "biddingDetail",
            meta: { title: null, noBreadCrumb: false },
            component: () => import("../views/BiddingHome/BiddingDetail.vue"),
          },
        ],
      },
      {
        path: "infomation",
        name: "infomation",
        meta: { title: "我的资料", noBreadCrumb: false },
        component: () => import("../views/Infomation/Infomation.vue"),
      },
    ],
    beforeEnter: (to, form, next) => {
      console.log(to, form, client, Cookies.get("client"), "to-form");
      if (to.path.split("/")[1] === Cookies.get("client")) {
        next();
      } else {
        next("/supplier/login");
        removeToken();
      }
    },
  },
  {
    path: "/user",
    component: Layout,
    name: "user",
    meta: {
      noBreadCrumb: true,
    },
    redirect: "/user/login",
    props: true,
    children: [
      {
        path: "supplierManage",
        name: "supplierManage",
        meta: { title: "供应商管理", noBreadCrumb: false, keepAlive: true },
        props: true,
        component: () => import("../views/SupplierManage/index.vue"),
        children: [
          {
            path: "detail",
            name: "supplierDetail",
            meta: { title: "注册资料", noBreadCrumb: false },
            component: () => import("../views/SupplierManage/Detail/index.vue"),
          },
        ],
      },
      {
        path: "supplierBidding",
        name: "supplierBidding",
        meta: { title: "竞价大厅", noBreadCrumb: false },
        component: () => import("../views/SupplierBidding/index.vue"),
        children: [
          {
            path: "supDetail",
            name: "supDetail",
            meta: { title: null, noBreadCrumb: false },
            component: () => import("../views/SupplierBidding/SupplierDetail.vue"),
          },
        ],
      },
      {
        path: "projectManage",
        name: "projectManage",
        meta: { title: "项目管理", noBreadCrumb: false },
        component: () => import("../views/ProjectManage/index.vue"),
      },
      {
        path: "userManage",
        name: "userManage",
        meta: { title: "用户管理", noBreadCrumb: false },
        component: () => import("../views/UserManage/index.vue"),
      },
    ],
    beforeEnter: (to, form, next) => {
      if (to.path.split("/")[1] === Cookies.get("client")) {
        next();
      } else {
        next("/user/login");
        removeToken();
      }
    },
  },
  {
    path: "/supplier/logon",
    name: "Logon",
    component: () => import("../views/Logon/Logon.vue"),
    meta: {
      noBreadCrumb: true,
    },
    beforeEnter: (to, form, next) => {
      next();
    },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("../views/ResetPassword/index.vue"),
    meta: {
      noBreadCrumb: true,
    },
    beforeEnter: (to, form, next) => {
      next();
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () => import("../views/Error/index.vue"),
    meta: {
      noBreadCrumb: true,
    },
    beforeEnter: (to, form, next) => {
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// router.beforeEach((to, from, next) => {
//   next();
// });

export default router;

<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive v-if="route.meta.keepAlive">
      <component :is="Component" :key="route.meta.usePathKey ? route.path : undefined" />
    </keep-alive>
    <component :is="Component" :key="route.meta.usePathKey ? route.path : undefined" v-if="!route.meta.keepAlive" />
  </router-view>
  <!-- <router-view v-slot="{ Component, route }">
    <transition
      name="fade"
      mode="out-in"
    >
      <keep-alive v-if="route.meta.keepAlive">
        <suspense>
          <template #default>
            <component
              :is="Component"
              :key="route.meta.usePathKey ? route.path : undefined"
            />
          </template>
          <template #fallback> Loading... </template>
        </suspense>
      </keep-alive>
      <suspense v-else>
        <template #default>
          <component
            :is="Component"
            :key="route.meta.usePathKey ? route.path : undefined"
          />
        </template>

        <template #fallback> Loading... </template>
      </suspense>
    </transition>
  </router-view> -->
</template>
<script lang="ts" setup>

</script>
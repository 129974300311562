import { promiseGet, promisePost } from "./httpRequest";
export const captchaImage = () => {
  return promiseGet("/captchaImage", {});
};
export const supplierlogin = (params) => {
  return promisePost("/supplierlogin", params);
};

export const userLogin = (params) => {
  return promisePost("/login", params);
};

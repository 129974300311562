import CryptoJS from "crypto-js";

import Cookies from "js-cookie";

const TokenKey = "Admin-Token";

/**
 * AES 加密
 * @param word: 需要加密的文本
 * KEY: // 需要前后端保持一致
 * mode: ECB // 需要前后端保持一致
 * pad: Pkcs7 //前端 Pkcs7 对应 后端 Pkcs5
 */
const KEY = CryptoJS.enc.Utf8.parse("d7b85f6e214abcde");

export const AES_Encrypt = (plaintext: any) => {
  const ciphertext = CryptoJS.AES.encrypt(plaintext, KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  return ciphertext;
};

/**
 * AES 解密
 * @param jsonStr
 */
export const AES_Decrypt = (jsonStr: any) => {
  const plaintext = CryptoJS.AES.decrypt(jsonStr, KEY, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);

  return plaintext;
};

export const getToken = () => {
  return sessionStorage.getItem(TokenKey);
};

export const setToken = (token: string) => {
  return sessionStorage.setItem(TokenKey, token);
};

export const removeToken = () => {
  return sessionStorage.removeItem(TokenKey);
};

//0.身份证正面 1.身份证背面 2.授权函 3.营业执照 4.资质认证

export const imageMap = {
  front: 0,
  converse: 1,
  letter: 2,
  businessLicense: 3,
  qualification: 4,
};

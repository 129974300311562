import { promisePost, promisePut } from "./httpRequest";

//校验验证码
export const checkUser = (params) => {
  return promisePost("/supplier/checkUser", params);
};

//重置密码
export const resetPsd = (params) => {
  return promisePost("/supplier/resetPsd", params);
};
//重置密码
export const resetPsdUser = (params) => {
  return promisePut("/system/user/resetPwd", params);
};

import axios from "./axios";
import { message } from "ant-design-vue";
const promiseGet = (url: string, params: Record<string, any> | undefined) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });

const promisePost = (url: string, params: Record<string, any> | undefined) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, params) //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });

const downloadPost = (url: string, params: Record<string, any> | undefined) =>
  new Promise((resolve, reject) => {
    axios({
      url,
      method: "post",
      data: params,
      // responseType: "arraybuffer",
      responseType: "blob",
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
const promiseDel = (url: string, params: Record<string, any> | undefined) =>
  new Promise((resolve, reject) => {
    axios
      .delete(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
const promisePut = (url: string, params: Record<string, any> | undefined) =>
  new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
export const exportExcel = (url: string, params: Record<string, any> | undefined, filename: string) => {
  downloadPost(url, params)
    .then((res: any) => {
      if (res.status == 200) {
        // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
        //将`blob`对象转化成一个可访问的`url`
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((err) => {
      console.log("导出失败", err);
      message.error(err);
    });
};
export { downloadPost, promiseGet, promisePost, promiseDel, promisePut };

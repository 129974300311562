import { validateUPN } from "@/utils/validator";
export const loginFormRules = (): object => {
  return {
    username: [
      {
        required: true,
        message: "请输入用户名!",
      },
    ],
    password: [
      {
        required: true,
        message: "请输入密码!",
      },
    ],
    code: [
      {
        required: true,
        message: "请输入验证码!",
      },
    ],
    upn: [
      {
        required: true,
        validator: validateUPN,
      },
    ],
  };
};

import type { Rule } from "ant-design-vue/es/form";
export const validateUPN = async (_rule: Rule, value: string) => {
  if (!value) {
    return Promise.reject("请勾选用户协议!");
  } else {
    return Promise.resolve();
  }
};

export const phoneVal = async (_rule: Rule, value: string) => {
  const reg = new RegExp(/^1[3456789]\d{9}$/);
  const v = value;
  if (v === "" || v === null) {
    return Promise.reject("请输入手机号!");
  } else if (!reg.test(v)) {
    return Promise.reject("请输入正确的手机号!");
  } else {
    return Promise.resolve();
  }
};

export const passwordVal = async (_rule: Rule, value: string) => {
  const p = value;
  const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
  if (p === "" || p === null) {
    return Promise.reject("请设置密码!");
  } else if (!reg.test(p)) {
    return Promise.reject("密码必须包含字母和数字!");
  } else if (p.length < 8 || p.length > 20) {
    return Promise.reject("密码长度为8到20位!");
  } else {
    return Promise.resolve();
  }
};

/**
 * axios 封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from "axios";

import router from "@/router";
// import store from '../store/index';
import { getToken } from "@/utils/index";

import { message } from "ant-design-vue";
import Cookies from "js-cookie";

class HttpRequest {
  static axiosInstance: any;

  static init() {
    // 创建 axios 实例
    this.axiosInstance = axios.create();
    // 设置请求超时
    this.axiosInstance.defaults.timeout = 10000;
    // 表示跨域请求时是否需要使用凭证
    this.axiosInstance.defaults.withCredentials = true;
    this.axiosInstance.defaults.baseURL = process.env.VUE_APP_BASE_API;

    // 设置 post 请求头    application/x-www-form-urlencoded;application/json;
    // this.axiosInstance.defaults.headers.common["Authorization"] = "AUTH_TOKEN";
    this.axiosInstance.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
    this.axiosInstance.all = axios.all; // 设置 axios 并发请求

    // 初始化拦截器
    this.initInterceptors();
    return this.axiosInstance;
  }

  static initInterceptors() {
    // 添加请求拦截器
    this.axiosInstance.interceptors.request.use(
      (config: any) => {
        // 在发送请求之前做些什么

        /* 登录流程控制中，根据本地是否存在 token 判断用户的登录情况
                 但是即使 token 存在，也有可能 token 是过期的，所以在每次的请求头中携带 token
                 后台根据携带的 token 判断用户的登录情况，并返回给我们对应的状态码
                 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作 */
        getToken() && (config.headers.Authorization = `Bearer ${getToken()}`);
        return config;
      },
      (error: Error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );

    // 添加响应拦截器
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        // 对响应数据做点什么
        const code = response.data.code;
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据，否则的话抛出错误
        if (response.status === 200) {
          if (code == 200 || code == 20 || response.data.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return Promise.resolve(response);
          } else {
            this.errorHandle(code, response.data.msg || response.data.message);
            return Promise.reject(response);
          }
        } else {
          return Promise.reject(response);
        }

        // return response;
      },
      (error: any) => {
        console.log(error, "error");
        // 超时提示
        if (error.message.includes("timeout")) {
          message.warning("请求超时");
          return Promise.reject(error);
        }

        /* 处理断网的情况
           eg: 请求超时或断网时，更新 state 的 network 状态
           network 状态在 app.vue 中控制着一个全局的断网提示组件的显示隐藏
           关于断网组件中的刷新重新获取数据，会在断网组件中说明 */
        if (!window.navigator.onLine) {
          // 如果断网....
          // store.commit('changeNetwork', false);
          message.warning("网络连接异常，请检查网络后刷新重试");
        } else {
          message.error("服务器请求错误");
          return Promise.reject(error);
        }
      }
    );
  }

  /**
   * 请求失败后的错误统一处理
   * @param {Number} status 请求失败的状态码
   * 1**  信息，服务器收到请求，需要请求者继续执行操作
   * 2**  成功，操作被成功接收并处理
   * 3**  重定向，需要进一步的操作以完成请求
   * 4**  客户端错误，请求包含语法错误或无法完成请求
   * 5**  服务器错误，服务器在处理请求的过程中发生了错误
   */
  static errorHandle(status: any, other: any) {
    const client = sessionStorage.getItem("client");
    const login = client === "user" ? "/user/login" : "/supplier/login";
    // 状态码判断
    switch (status) {
      // 400: 请求错误
      case 400:
        message.error("请求错误");
        break;
      // 401: 未登录状态，跳转登录页
      case 401:
        // loginOut();
        message.info("登陆状态已过期，请重新登录");
        setTimeout(() => {
          router.replace(login);
        }, 1000);

        break;
      // 403 权限不足，拒绝访问
      case 403:
        message.warning("权限不足");
        break;
      // 404请求不存在
      case 404:
        message.info("请求的资源不存在或请求地址出错");
        break;
      // 服务器错误
      case 500:
        message.error(other || "服务器请求错误");
        break;
      default:
        console.log(other);
    }
  }

  /**
   * 跳转登录页
   * 携带当前页面路由，在登录页面完成登录后返回当前页面
   */
  static loginOut = () => {
    if (window.localStorage.loginOut == "false" || window.localStorage.loginOut == undefined) {
      // 解决：token验证过期，发送多次请求，导致消息多次提示
      window.localStorage.loginOut = "true"; // 更改状态，防止发送多次请求，导致消息多次提示
      message.warning("登陆状态已过期，请重新登录");
    }
  };
}

export default HttpRequest.init();

import { createApp } from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue/es";
import "ant-design-vue/dist/antd.css";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import "./assets/icons/";
dayjs.extend(duration);
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
import "@/assets/icons/index.ts";

import SvgIcon from "@/components/SvgIcon/index.vue";

const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://09a7fb33969d45caaf112e9b8874ede4@sentry.cccc58.com/8",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://bidding.cccc58.com/"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),

    new Sentry.Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: "supplier@0.1.0",
});

// 注册到全局
app.component("svg-icon", SvgIcon);
app.use(store).use(router).use(Antd).mount("#app");

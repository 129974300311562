
import { defineComponent, reactive, onBeforeMount, watch } from "vue";
import ContentView from "./ContentView.vue";
import RouterLinkVue from "./RouterLink.vue";
import BreadCrumbVue from "./BreadCrumb.vue";
import type { MenuProps } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import Cookies from "js-cookie";
import { useStore } from "vuex";
import { removeToken } from "@/utils";
import ResetPassword from "@/components/ResetPassword/index.vue";
import { supplierInfo } from "@/api/supplierManage";
import { UserOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    ContentView,
    RouterLinkVue,
    BreadCrumbVue,
    ResetPassword,
    UserOutlined,
  },
  setup() {
    const menu_supplier = [
      {
        path: "/supplier/infomation",
        meta: { title: "我的资料" },
      },
    ];
    const menu_user = [
      {
        path: "/user/supplierManage",
        meta: { title: "供应商管理" },
      },
      {
        path: "/user/projectManage",
        meta: { title: "项目管理" },
      },
      {
        path: "/user/supplierBidding",
        meta: { title: "竞价大厅" },
      },
      {
        path: "/user/userManage",
        meta: { title: "用户管理" },
      },
    ];
    const app_menu = [
      {
        path: "/supplier/supplierHome",
        meta: { title: "供应商首页" },
      },
      {
        path: "/supplier/biddingHome",
        meta: { title: "供应商大厅" },
      },
    ];
    const route = useRoute();
    const router = useRouter();
    sessionStorage.setItem("client", route.matched[1].path.split("/")[1]);
    const userName = sessionStorage.getItem("username") || "";
    const client = sessionStorage.getItem("client");
    const state: any = reactive({
      selectedKeys: [route.matched[1].path],
      menu: client === "user" ? menu_user : menu_supplier,
      info: {},
      visible: false,
    });

    const menuSelected: MenuProps["onClick"] = (menu: any) => {
      state.selectedKeys = [menu.key];
    };

    const logOut = () => {
      const login = client === "user" ? "/user/login" : "/supplier/login";
      router.replace(login);
      removeToken();
    };
    onBeforeMount(() => {
      client === "supplier" && getSupplierInfo();
      // if (client === "supplier" && Cookies.get("state") === "1")
      //   state.menu = app_menu.concat(menu_supplier);
    });
    const getSupplierInfo = () => {
      supplierInfo({ userName: sessionStorage.getItem("username") }).then((res: any) => {
        //console.log(res);
        if (res.code === 200) {
          state.info = res.data.supplier;
          sessionStorage.setItem("state", res.data.supplier.state);
          if (res.data.supplier.state !== "1") {
            router.replace("/supplier/infomation");
          }
          state.menu = sessionStorage.getItem("state") === "1" ? app_menu.concat(menu_supplier) : menu_supplier;
        }
      });
    };
    watch(route, (currentRoute) => {
      if (currentRoute.path.includes("supplier") && !currentRoute.path.includes("user")) {
        getSupplierInfo();
      }

      const s = state.menu.filter((item: any) => currentRoute.path.includes(item.path));
      state.selectedKeys = s.map((item) => item.path);
      console.log(133);
    });
    const resetPassword = () => {
      state.visible = true;
    };
    const updateVisible = (v: boolean) => {
      state.visible = v;
    };
    return {
      state,
      route,
      router,
      menuSelected,
      userName,
      logOut,
      resetPassword,
      updateVisible,
    };
  },
});
